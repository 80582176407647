/* .products_header > p {
 text-align: center;
 color: #ef6718;
} */


.container{
  margin: 0;
  padding: 0;
  max-width: 100% !important;
  width: 100%;
}
.products_title{
  color: #ef6718;
  text-align: center;
  /* display: flex;
  justify-content: center; */
  position:absolute;
  margin-top: -200px;
  max-width: 100%;
  z-index: 1;
  font-size: 40px;
  font-weight: bold;
  align-items: center;
  padding-left: 30px;
}
.black_bgs{
  width: 100%;
  max-width: 100%;
  background-color: #000;
  height: 300px;
  position: absolute;
  top: 0;
  opacity: 0.5;
}
.products_desc{
  position: absolute;
  margin-top: -100px;
  /* width: 400px; */
  color: #fff;
  max-width: 100%;
  padding-left: 30px;
}
.image_bg{
  max-width: 100%;
  height:300px;
  width: 100%;
}
.inner_image{
  overflow:hidden;
}

.row .col s12 m6 l4{
  padding: 0;
}


.card {
  overflow:hidden;
  border-radius: 10px;
  width: 330px;
  height: 460px;
  box-shadow: 0px 7px 10px rgba(black, 0.5)
}

.project_name {
  color: #ef6718;
}

.image_product {
  width: 100%;
  border-style: none;

}
.image_product:hover {
  transform: scale(1.5);
  transition: all 0.5s ease;
  border-radius: 10px;
}

.card-body{
  padding: 3px;
}
.card-title{
  font-weight: bold;
}
.card-text{
  /* padding-bottom: 2px; */
  font-size: 12px;
}

@media only screen and (max-width: 700px) {
  /* .products_desc{
    width: 200px;
  } */
}
.some{
  padding-top:50px !important;
  text-align:center;
  font-size:40px;
}

.service{

  padding-left: 130px !important;
  padding-right:130px !important;
}
@media (max-width: 768px) {
.service {
    padding-right: 30px !important;
    padding-left: 30px !important;
}
}

@media (max-width: 576px) {
.service{
    padding: 20px !important;
}
}

@media (max-width: 480px) {
.service{
    padding-right: 10px !important;
    padding-left: 10px !important;
}
}
.arrow_left{
  display: flex;
  justify-content:space-between;
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.arrow_icon:hover{
  color: #000;
}