

.service-cont {
    max-width: 100%;
}

.service_desc {
    text-align: center;
    margin-left: 20%;
    margin-right: 20%;
}

.servicerow {
   margin-left: 15%;
   margin-right: 15%;
}

.imagetest {
    /* width: 400px;
    height: 400px; */
    width: 90%;
    height: 90%;
}

/* .service_list {} */

.text_illustration_desc {
    /* width: 80%; */
    font-size: 19px;
}

.image_illustration {
    align-items: center;
    display: flex;
    justify-content: center;
}

.whatwedo {
    font-size: 30px;
    font-weight: bold;
    padding: 1%;
}

.whatwedodesc {
    padding-bottom: 10%;
    font-size: 19px;
}

.text_illustration_title {
    font-weight: bold;
    font-size: 36px;
    color: #000;
}