.container{
    width: 100%;
    height: 100%;
    max-width: 100% !important;
}

.service_main{
  position: relative;
  text-align: center;
  color:white;
  font-size:45px;
 

}

.centered{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.serv_image{
  width: 100%;
  height: 600px;
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center;
  margin-bottom: 20px;
  opacity: 0.5;
  z-index: -2;
}






.services_bg{
  width: 100%;
  height: 600px;
  background-color: #000000;
  opacity:1;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

}

  
  .project_name {
    color: #ef6718;
  }
  
  .image_product {
    width: 100%;
    border-style: none;
  
  }

  .row .col.l4{
    display: flex;
    justify-content: center;
  }
  .image_product:hover {
    transform: scale(1.05);
    transition: all 0.5s ease;
    border-radius: 10px;
  }
  
  .card-title,.card-text{
    padding-left: 20px;
    padding-right: 20px;
  }
  .card-text{
    font-size:13px;
  }
  .card-title{
    font-weight: bold;
  }
  /* .card-text{
    padding-bottom: 5px;
  } */

  .service_icon{
    font-size: 50px;
    color: #ef6718;
  }
  .service_title{
    color :#fff;
    margin:50% auto 9 !important;
    z-index: 1;
  }
  .cardstyle:hover{
    transform: scale(1.05);
    border-radius: 10px;
    box-shadow:0 4px 30px #0C65EA;

  }

  .services_padding{
    padding-left: 120px;
    padding-right: 120px;
  }

  
@media (max-width: 768px) {
  .services_padding {
      padding-right: 30px;
      padding-left: 30px;
  }
}

@media (max-width: 576px) {
  .services_padding {
      padding: 20px;
  }
}

@media (max-width: 480px) {
  .services_padding{
      padding-right: 10px;
      padding-left: 10px;
  }
}