.main-nav .logo {
  display: flex;
  margin-left: 0.5em;
  flex-direction: column;
  font-size: 1.9em;
  letter-spacing: 0.2em;
  font-weight: bolder;
  color: whitesmoke;
  width: fit-content;
  height: fit-content;
}

.navcontact{
  color:#fff !important;
}
.navcontact:hover{
  color:#000 !important;
}

.links {
  display: flex;
}

.logo>i {
  font-size: x-small;
  margin: 0;
}

nav .rc-menu-button {
  background-color: #fff;
  color: whitesmoke;
  border: none;

  letter-spacing: 0.195em;

  font-weight: bold;
}

nav .rc-menu-button:focus {
  background-color: #0c0c0e;
  color: whitesmoke;
}

/* nav .rc-menu-button:hover {
  background-color: #0c0c0e;
  color: whitesmoke;
} */

.logo>img {
  width: 2em;
  height: 2em;
}

.contactus_nav {
  background-color: #fff;
  color: #000;
}

.main-nav nav a {
  color: #000;
  font-weight: bold;
}

.main-nav nav {
 
  display: flex;
  justify-content: space-between;
  height: fit-content;
  background-color: #fff;
  color: #000;
  box-shadow: none;
  padding-right: 0.5em;
  padding-left: 0.5em;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.main-nav a {
  color: whitesmoke;
  letter-spacing: 0.195em;
}
.btnlink{
  color: #fff;
 
}
.btnlink:hover{
  /* background-color: #fff;
  border: 1px solid #0C65EA; */
  color: #000;
  background-color: #fff;
}


.btn_contactus:hover {
  background-color: #fff;
  color: #000;
}

/* .btn_contactus a{
  color:white;
} */
.btn_contactus {
  padding: 5px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #0C65EA;
  background-color: #0C65EA;
  color: #fff;
  font-style: normal;
  font-weight: bold;
}

@media only screen and (max-width: 700px) {
  .main-nav nav {
    padding: 0.5em;
    flex-direction: row-reverse;
  }

  .main-nav .logo {
    margin-left: 0.5em;
  }
}
.current {
  border-bottom: 4px solid 000;
}

/* .sidenav {
  padding-top: 8em;
  display: flex;
  flex-direction: column;
  text-align: left;
  opacity: 0.97;
  background-color: #fff;
  color: #000;

  padding-left: 2em !important;
}

.nav_home{
  
} */
.sidenav{
  background-color:#0C65EA;
}

.sidenav li>a {
  padding-top: 5em !important;
  display: flex;
  flex-direction: column;
  color: white;
  display: block;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
  text-align: left;
}

.collapsible {
  background-color: #0c0c0e;
  color: whitesmoke;
  text-align: center;
}

.collapsible .collapsible-body {
  background-color: #0c0c0e;
  color: whitesmoke;
  text-align: left;
  padding-left: 1.5em;
}

.collapsible .collapsible-body>div {
  display: flex;
  flex-direction: column;
}

.collapsible-header {
  color: #ef6718;
  font-weight: bold;
  font-size: medium;
  letter-spacing: 0.2em;
}