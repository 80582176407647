
 .meet_team{
     text-align: center;
     font-size: 30px;
     margin-top: 30px;
 }
 .image_team{
     width: 100%;
 }
 .member-details{
     text-align: center;
 }
  
