.startup {
  width: 100vw;
  color: whitesmoke;
  background-color: #0c0c0e;
  padding: 8em;
  margin-top: 4em;
  font-size: smaller;
}
.startup span {
  color: #ef6718;
}

@media only screen and (max-width: 700px) {
  .startup {
    padding: 2em;
    padding-top: 4em;
  }
}
