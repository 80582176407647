.image_empty{
    /* background-color: #e5e5e5; */
    max-width: 100%;
}
@media screen and (max-width: 767px) {
    .image_empty_img{
        padding-top: 25% !important;
       
    }
}
    
