.contact {
  padding-top: 10em;
  min-height: 100vh;
  /* background: url("../../static/images/pexels-sora-shimazaki-5926397.jpg"); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fff;
  color: #0C65EA;
}
.contact > div {
  margin-left: 20%;
  margin-right: 20%;
}
.contact * + * {
  margin-top: 0.9em;
}
.contact button {
  width: 100%;
  background-color:#0C65EA !important;
}
.contact button:hover{
  border: 1px solid #0C65EA !important;
  background-color:#fff;
}
input:not([type]):focus:not([readonly]), input[type="text"]:not(.browser-default):focus:not([readonly]), input[type="password"]:not(.browser-default):focus:not([readonly]), input[type="email"]:not(.browser-default):focus:not([readonly]), input[type="url"]:not(.browser-default):focus:not([readonly]), input[type="time"]:not(.browser-default):focus:not([readonly]), input[type="date"]:not(.browser-default):focus:not([readonly]), input[type="datetime"]:not(.browser-default):focus:not([readonly]), input[type="datetime-local"]:not(.browser-default):focus:not([readonly]), input[type="tel"]:not(.browser-default):focus:not([readonly]), input[type="number"]:not(.browser-default):focus:not([readonly]), input[type="search"]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 1px solid black;
    -webkit-box-shadow: 0 1px 0 0 black;
    box-shadow: 0 1px 0 0 black;
}