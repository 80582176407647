.home {
    background-color: #e5e5e5;
    background-image: url("../../static/images/try_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    overflow-x: hidden;
}

.home_image {
    padding-top: 20%;
    width: 550px;
    /* height: 700px; */
    max-width: 100%;

}

.company_about{
    max-width: 100%;
    overflow-x: hidden;
    width: 90%;

}
.company_about{
    font-size: 20px;
    color: #000;
}

.company_name {
    padding-top: 200px;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 59px;
    color: #0C65EA;
    max-width: 100%;
}

.contact_home_btn {
    background-color: #0C65EA;
    color: #fff;
    width: 196px;
    height: 50px;
    border-radius: 10px;
    border: none;

}
.contact_home_btn:hover{
    background-color: #fff;
    border: 1px solid #0C65EA;
    color: #000;
}