:root {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  width: 100vw;
  overflow-x: hidden;
  max-width: 100%;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

.App * {
  font-family: "Poppins", sans-serif;
}
