.products_title_home {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    color: #000;
    margin-top: 30px;
    margin-bottom: 30px;

}

.products_home {
    /* background-color: #F2F9FF; */
    padding-bottom: 40px;
    padding-top: 40px;
}

.homeservice {
    padding-right: 130px;
    padding-left: 130px;
}

@media (max-width: 768px) {
    .homeservice {
        padding-right: 30px;
        padding-left: 30px;
    }
}

@media (max-width: 576px) {
    .homeservice {
        padding: 20px;
    }
}

@media (max-width: 480px) {
    .homeservice {
        padding-right: 10px;
        padding-left: 10px;
    }
}

.more_service_btn {
    text-align: center;
    font-style: normal;
    color: #0C65EA;
    width: 130px;
    border-radius: 10px;
    padding: 10px;
    background-color: #FFFFFF;
    border: 1px solid #0C65EA;
}

.more_service_btn:hover {
    background-color: #0C65EA;
    color: #fff !important;
    border: none;

}

.products_home_container {
    text-align: center;
    font-size: 19px;
    padding-bottom: 10px;
    color: #000;
    margin-left: 20%;
    margin-right: 20%;
}