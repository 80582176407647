.icon_style {
    padding-left: 20px;
    padding-bottom: 10px;
}


.home_title {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    color: #000;
    margin-top: 20px;
}
.button_service{
    display:flex;
    align-items: center;
    justify-content: center;
}
.btn {
    /* display: flex;
    justify-content: center; */
    color: white;
    width: 256px;
    height: 64px;
    line-height: 64px;
    transition: all 0.3s;
    /* span {
      transition: all 0.3s;
      tranform: scale(1, 1);
    } */
  }
  .service_test{
      display: flex;
      justify-content: space-between;
      margin-left: 13%;
      margin-right: 13%;
      /* margin-left: 100px;
      margin-right: 100px;
      width: 80%;
      align-items: center; */
  }
 
  .container_service{
    background-color: #F2F9FF;
  }
  .homeBtnServices{
    text-align: center;
    font-style: normal;
    color: #0C65EA;
    width: 130px;
    border-radius: 10px;
    padding: 10px;
    background-color: #FFFFFF;
    border: 1px solid #0C65EA;
    margin-bottom: 30px;
  }
  .cardstyle:hover{
    box-shadow: 0px 0px 10px 0px rgba(12, 101, 234, 1);
    transition: all 0.3s;  
  }

  .service_home {
    padding-right: 130px;
    padding-left: 130px;
}

@media (max-width: 768px) {
    .service_home {
        padding-right: 30px;
        padding-left: 30px;
    }
}

@media (max-width: 576px) {
    .service_home {
        padding: 20px;
    }
}

@media (max-width: 480px) {
    .service_home {
        padding-right: 10px;
        padding-left: 10px;
    }
}


