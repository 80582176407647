/* .footer {
  padding-top: 4em;
  background-color: #0c0c0e;
  height: fit-content;
  padding: 1.5em;
  width: 100vw;
  color: whitesmoke;
  text-align: center;
}
.social * + * {
  margin-left: 1.5em;
}

@media only screen and (max-width: 700px) {
  .social * + * {
    margin-left: 0;
  }
  .social {
    display: flex;
    justify-content: space-around;
  }
} */

.contact_footer{
  /* display: flex;
  justify-content: space-around; */
  margin-right: 10px;
  margin-left: 10px;
}


/* The footer is fixed to the bottom of the page */


@media (max-height:800px) {
  footer {
      /* position: fixed; */
      bottom: 0;
  }
  header {
      padding-top: 40px;
  }
}

.footer-distributed {
  background-color: #3F71EA;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: bold 16px;
  padding: 50px 50px 60px 50px;
  margin-top: 80px;
}

.footer-distributed .footer-left, .footer-distributed .footer-center, .footer-distributed .footer-right {
  display: inline-block;
  vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left {
  width: 30%;
}

.footer-distributed h3 {
  color: #ffffff;
  font: normal 36px;
  margin: 0;
}


.footer-distributed h3 span {
  color: #e0ac1c;
  padding: 0 5px;
}

/* Footer links */

.footer-distributed .footer-links {
  color: #ffffff;
  margin: 20px 0 12px;
}

.footer-distributed .footer-links a {
  display: inline-block;
  line-height: 1.8;
  text-decoration: none;
  color: #fff;
}
.footer-distributed .footer-links a:hover {
  color: #000;
}

.footer-distributed .footer-company-name {
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center {
  width: 35%;
}

.footer-distributed .footer-center i {
  background-color: #3F71EA;
  color: #ffffff;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer-distributed .footer-center p {
  display: inline-block;
  color: #ffffff;
  vertical-align: middle;
  margin: 0;
}

.footer-distributed .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer-distributed .footer-center p a {
  color: #e0ac1c;
  text-decoration: none;
  padding: 3px;
  
}

/* Footer Right */

.footer-distributed .footer-right {
  width: 30%;
}

.footer-distributed .footer-company-about {
  line-height: 20px;
  color: #fff;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer-distributed .footer-company-about span {
  display: block;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-distributed .footer-icons {
  margin-top: 25px;
}

.footer-distributed .footer-icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #33383b;
  border-radius: 2px;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;
  margin-right: 3px;
  margin-bottom: 5px;
}

.footer-distributed .footer-icons a:hover {
  background-color: #3F71EA;
}

.footer-links a:hover {
  color: #3F71EA;
}

@media (max-width: 880px) {
  .footer-distributed .footer-left, .footer-distributed .footer-center, .footer-distributed .footer-right {
      display: block;
      width: 100%;
      margin-bottom: 40px;
      text-align: center;
  }
  .footer-distributed .footer-center i {
      margin-left: 0;
  }
}       
      
