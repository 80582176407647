.dev {
  width: 100vw;
  display: flex;
  flex-direction: column;
  margin-top: 4em;
}

.dev .title {
  display: flex;
  flex-direction: row-reverse;
  height: 100vh;
  background: url("../../static/images/pexels-fauxels-3184454.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  color: #1e1a17;
  background-size: cover;
  width: 100vw;
  padding: 4em;

  background-color: whitesmoke;
}

.title > div {
  display: flex;
  flex-direction: column;
  width: 50%;
  font-size: medium;
  margin-top: 5%;
  font-weight: bold;
  letter-spacing: 0.2em;
  margin-right: 2em;
}
.dev .content {
  width: 100vw;
  text-align: center;
}
.content h5 {
  font-size: medium;
  height: fit-content;
  padding: 5em;
}

.content > .projects {
  display: flex;
  width: 100vh;
  justify-content: space-around;
  flex-wrap: wrap;
}

.content .card {
  width: 40%;
  text-align: left;
}
.content .card-title {
  color: #ef6718;
}

@media only screen and (max-width: 700px) {
  .content h5 {
    text-align: left;
  }

  .dev .title {
    width: 100vw;
  }
  .content > .projects {
    display: flex;
    flex-direction: column;
    width: 100vw;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .content .card {
    width: 70%;
    margin-left: 15%;
    text-align: left;
  }
  .title > div {
    width: 90%;
    color: black;
    margin-top: 8em;
    letter-spacing: 0.3em;
  }
}
