.more_about{
    padding-left: 5%;
    padding-right: 5%;
    height: auto;
    background-color: #fff;
    padding: 20px;
    margin-top: 20px;
    border-radius: 5px;
  text-align: center;
}
.about_title{
    padding-top: 4%;
    text-align: center;
    font-weight: bold;
    color: #000;
    font-size: 30px;
}
.team_title{
    padding-top: 4%;
    text-align: center;
    font-weight: bold;
    color: #000;
    font-size: 30px;
}
.team_hr{
    width: 100%;
    height: 1px;
    background-color: #000;
    margin-top: 10px;
    margin-bottom: 10px;
}

.about_hr{
    width:10%;
    border-top: 5px solid #000;
    padding-left: 10%;
    padding-right: 10%;
}

.role{
    text-align: center;
    font-size: 20px;
    color: #000;
    
}

@media screen and (max-width: 768px) {
  .about_title{
    padding-top: 10%;
   
  }
}

@media screen and (max-width: 1024px) {
  .about_title{
    padding-top: 10%;
   
  }
}

.our_team_desc{
  text-align: center;
  font-size: 20px;
  color: #000;
  padding-top: 10px;

}
/* .space{
  padding-top: 5%;

} */

/* social icons styling with hover */
.social-icons {
  width: 100%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

    

    
