/* .headerImage {
  max-width: 100%;
  height: 300px !important;
} */
.container{
  max-width: 100% !important;
}
.contactForm{
  max-width: 100% !important;
}
.headerServiceText {
  font-size: 40px;
  color: #000;
  text-align: center;
}
.bluecard {
  margin-top: 70px !important;
  height: 330px !important;
  background-color: #0c65ea;
  position: absolute;
  margin-left: 230px !important;
  /* margin-left: 500px; */
  z-index: 1;
  margin-left: 20%;
}

@media screen and (max-width: 1330px) {
  .bluecard {
 
    margin-left: 13% !important;
    
  }
.formcard {
  
    margin-left: -180px;
}
}

@media screen and (max-width: 1347px) {
  .bluecard {
 
    margin-left: 200px !important;
  
  }
  .formcard {
    margin-left: -180px;
}
}
  

.formcard {
  width: 600px !important;
  margin-left: -220px;
}
.formTitle {
  padding-top: 30px !important;
  padding-left: 100px;
  font-weight: bold;
}
.formInput {
  margin-left: 100px !important;
  background: #ffffff;
  border: 1px solid #acb0b7 !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  width: 450px !important;
  padding-left: 10px !important;
}
.submitMessage {
  background: #0c65ea !important;
  border-radius: 7px;
  color: #fff;
  margin-left: 100px;
  width: 160px;
  height: 50px;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
}
.submitMessage:hover {
  border: 1px solid #0c65ea;
  color: #0c65ea;
  background-color: #fff !important;
}
.contact_location,
.contact_phone,
.contact_mail {
  /* padding-left: 40px; */
  text-align: center;
}
.inTouch {
  color: #fff;
  text-align: center;
}
.details {
  color: #fff;
}
.servImage {
  max-width: 100%;
  width: 100%;
  height: 300px;
}

@media only screen and (max-width: 1310px) {
  .dissapear {
    display: none;
  }
  .formcard {
    width: 400px !important;
    max-width: 100% !important;
    margin-left: 100px;
    
  }
  .formInput {
    width: 200px;
  }
}
.formInput2 {
  background: #ffffff;
  border: 1px solid #acb0b7 !important;
  box-sizing: border-box;
  border-radius: 5px;
 
}
.card2 {
  width: 400px !important;
  display: flex;
  justify-content: center;
}
.submitMessage2 {
  background: #0c65ea !important;
  border-radius: 7px;
  color: #fff;
  margin-left: 110px;
  margin: 0 auto !important;
  width: 160px;
  height: 50px;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
}
.submitMessage2:hover {
  border: 1px solid #0c65ea;
  color: #0c65ea;
  background-color: #fff !important;
}
.form2 {
  max-width: 100% !important;
}
.secondDisplay {
  margin-right: 40% !important;
  margin-left: 40% !important;
  max-width: 100% !important;
  width: 100% !important;
}
.finalcard {
  margin: 0 auto !important;
  height: 300px !important;
  /* padding-left: 20px !important;
  padding-right: 20px !important; */
  width: 500px !important;
}
.inputField{
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 7px !important;
}


@media only screen and (min-width: 1300px) {
  .mobileContainer {
    display: none;
  }

}

@media only screen and (min-width: 360px) {
  .finalcard{
    width: 330px !important;
  }
}


@media only screen and (min-width: 320px) {
  .finalcard{
    width: 330px !important;
  }
}


  
  


